// src/components/Header.jsx
import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <header className="bg-gradient-to-r from-primary-light to-primary-dark text-white">
      <div className="container mx-auto px-4 py-4 flex justify-between items-center">
        <motion.div 
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Link to="/" className="text-2xl font-bold flex items-center">
            <span className="mr-2">Best11</span>
          </Link>
        </motion.div>
        
        <motion.nav 
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
          className="hidden md:flex space-x-6"
        >
          <Link to="/" className="hover:text-secondary-light transition duration-200">Home</Link>
          <Link to="#how-it-works" className="hover:text-secondary-light transition duration-200">How It Works</Link>
          <Link to="#features" className="hover:text-secondary-light transition duration-200">Features</Link>
        </motion.nav>
        
        <motion.div 
          initial={{ opacity: 0, x: 20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5, delay: 0.4 }}
          className="flex space-x-3"
        >
          <Link to="/login" className="px-4 py-2 bg-white text-primary-dark rounded-lg hover:bg-gray-100 transition duration-200">
            Login
          </Link>
          <Link to="/signup" className="px-4 py-2 bg-secondary-light text-white rounded-lg hover:bg-secondary-dark transition duration-200">
            Sign Up
          </Link>
        </motion.div>
      </div>
    </header>
  );
};

export default Header;