// src/components/Hero.jsx
import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

const Hero = () => {
  return (
    <section className="bg-gradient-to-r from-primary-light to-primary-dark text-white py-16 md:py-24 relative overflow-hidden">
      <div className="absolute inset-0 bg-[url('/public/stadium-bg.jpg')] bg-cover bg-center opacity-20"></div>
      <div className="container mx-auto px-4 relative z-10">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-center max-w-3xl mx-auto"
        >
          <h1 className="text-4xl md:text-5xl font-bold mb-4">
            Best11 – The Ultimate IPL Fantasy Challenge!
          </h1>
          <p className="text-lg md:text-xl mb-8">
            Create Your Dream 11, and Win Prizes! Join thousands of cricket fans in the most exciting fantasy cricket experience.
          </p>
          <div className="flex flex-col sm:flex-row justify-center gap-4">
            <Link to="/create-team" className="px-8 py-3 bg-white text-primary-dark rounded-lg hover:bg-gray-100 transition duration-200 font-medium">
              Create Your Team
            </Link>
            <Link to="/login" className="px-8 py-3 bg-secondary-light text-white rounded-lg hover:bg-secondary-dark transition duration-200 font-medium">
              Predict the Match
            </Link>
          </div>
        </motion.div>
      </div>
    </section>
  );
};

export default Hero;