// src/pages/Prediction.jsx
import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import PredictionForm from '../components/PredictionForm';

const Prediction = () => {
  return (
    <div className="min-h-screen flex flex-col">
      <Header />
      <main className="flex-grow">
        {/* Hero Section for Prediction Page */}
        <section className="bg-gradient-to-r from-primary-light to-primary-dark text-white py-12">
          <div className="container mx-auto px-4">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              className="text-center"
            >
              <h1 className="text-3xl md:text-4xl font-bold mb-4">Predict & Win</h1>
              <p className="text-lg mb-0">Make your match predictions and win exciting prizes!</p>
            </motion.div>
          </div>
        </section>
        
        {/* Form Section */}
        <section className="py-12 bg-background-light">
          <div className="container mx-auto px-4">
            <div className="max-w-3xl mx-auto">
              <PredictionForm />
            </div>
          </div>
        </section>
        
        {/* Additional Info Section */}
        <section className="py-12 bg-white">
          <div className="container mx-auto px-4">
            <div className="max-w-3xl mx-auto text-center">
              <h2 className="text-2xl font-bold mb-6">How Predictions Work</h2>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                <div className="p-4">
                  <div className="w-12 h-12 bg-primary-light text-white rounded-full flex items-center justify-center mx-auto mb-4">
                    <span>1</span>
                  </div>
                  <h3 className="font-semibold mb-2">Submit Your Prediction</h3>
                  <p className="text-gray-600">Fill in the form with your match predictions before the game starts.</p>
                </div>
                <div className="p-4">
                  <div className="w-12 h-12 bg-primary-light text-white rounded-full flex items-center justify-center mx-auto mb-4">
                    <span>2</span>
                  </div>
                  <h3 className="font-semibold mb-2">Match Results</h3>
                  <p className="text-gray-600">Watch the match and see if your predictions come true.</p>
                </div>
                <div className="p-4">
                  <div className="w-12 h-12 bg-primary-light text-white rounded-full flex items-center justify-center mx-auto mb-4">
                    <span>3</span>
                  </div>
                  <h3 className="font-semibold mb-2">Claim Rewards</h3>
                  <p className="text-gray-600">Earn points and win prizes for accurate predictions!</p>
                </div>
              </div>
              <div className="mt-8">
                <Link to="/" className="text-primary-dark hover:text-primary-light transition duration-200">
                  Back to Home
                </Link>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default Prediction;