// src/components/WhyBest11.jsx
import React from 'react';
import { motion } from 'framer-motion';

const features = [
  {
    icon: "🏆",
    title: "Tournament-Based Fantasy",
    description: "Pick a team for the whole tournament, not just single matches."
  },
  {
    icon: "⚡",
    title: "Real-Time Updates",
    description: "Live stats and automatic point calculations as matches progress."
  },
  {
    icon: "🧠",
    title: "Strategic & Engaging",
    description: "No mid-tournament changes, making strategy key to success."
  },
  {
    icon: "💰",
    title: "Massive Prizes",
    description: "Compete for top rewards in every tournament!"
  }
];

const WhyBest11 = () => {
  return (
    <section className="py-16 bg-background-light">
      <div className="container mx-auto px-4">
        <motion.h2 
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          viewport={{ once: true }}
          className="text-3xl md:text-4xl font-bold text-center mb-12"
        >
          Why Best11?
        </motion.h2>
        
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
          {features.map((feature, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              viewport={{ once: true }}
              className="text-center"
            >
              <div className="w-12 h-12 bg-primary-light text-white text-2xl rounded-full flex items-center justify-center mx-auto mb-4">
                {feature.icon}
              </div>
              <h3 className="text-lg font-bold mb-2">{feature.title}</h3>
              <p className="text-gray-600 text-sm">{feature.description}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default WhyBest11;