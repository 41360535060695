import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// Import pages
import Home from "./pages/Home";
import Prediction from "./pages/Prediction";
import RulesPage from "./pages/RulesPage";
import QuizApp from "./pages/Questions";
import Login from "./components/Login";
import Signup from "./components/Signup";
import ForgotPassword from "./components/ForgotPassword"; 
// Import PrivateRoute
import PrivateRoute from "./components/PrivateRoute";
import "./index.css";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/quiz" element={<PrivateRoute element={<QuizApp />} />} />
        <Route path="/predict" element={<Prediction />} />
        <Route path="/rules" element={<PrivateRoute element={<RulesPage />} />} />
        <Route path="/how-it-works" element={<Home />} />
        <Route path="/features" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/create-team" element={<Home />} />
        <Route path="*" element={<Home />} />
      </Routes>
    </Router>
  );
}

export default App;