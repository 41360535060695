// src/firebase/config.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyDEpwLQikOsDgX1rTmu4t3CECe5D-w0444",
    authDomain: "best-11-e522e.firebaseapp.com",
    projectId: "best-11-e522e",
    storageBucket: "best-11-e522e.firebasestorage.app",
    messagingSenderId: "829458749780",  
    appId: "1:829458749780:web:2d90978f912d6970e3d0c1"

  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);

export { db, auth };