import React from 'react';
import { ArrowRight, Trophy, Shield, Bell, Award } from 'lucide-react';

const RulesPage = () => {
  return (
    <div className="max-w-4xl mx-auto py-12 px-4 sm:px-6 lg:px-8 bg-gray-50">
      <div className="bg-white shadow-xl rounded-lg overflow-hidden">
        {/* Header Banner */}
        <div className="bg-gradient-to-r from-indigo-600 to-purple-600 p-8 text-white">
          <h1 className="text-3xl font-bold">Contest Rules</h1>
          <p className="mt-2 text-indigo-100">Please read the following rules carefully before participating</p>
        </div>
        
        {/* Rules Content */}
        <div className="p-8">
          <div className="space-y-8">
            {/* How to Play */}
            <div className="flex gap-4">
              <div className="flex-shrink-0">
                <div className="bg-indigo-100 p-3 rounded-full">
                  <Trophy className="h-6 w-6 text-indigo-600" />
                </div>
              </div>
              <div>
                <h2 className="text-xl font-semibold text-gray-800 mb-3">How to Play</h2>
                <div className="space-y-2 text-gray-600">
                <ul className="list-disc pl-5 space-y-1">
                  <li>You will be given 20 prediction questions.</li>
                  <li>Write your answers wisely based on past performance and intuition.</li>
                  <li>Once submitted, your predictions cannot be changed.</li>
                  <li>The deadline to submit all predictions is before March 28.</li>
                  <li>You earn points based on correct predictions.</li>
                  <li>The leaderboard will be updated as the tournament progresses.</li>
                  <li>The player with the highest points at the end of IPL 2025 will be declared the winner.</li>
                  <li>In case of a tie, a tiebreaker will be applied based on accuracy in high-value questions.</li>
                </ul>
                </div>
              </div>
            </div>
            
            {/* Fair Play */}
            <div className="flex gap-4">
              <div className="flex-shrink-0">
                <div className="bg-indigo-100 p-3 rounded-full">
                  <Shield className="h-6 w-6 text-indigo-600" />
                </div>
              </div>
              <div>
                <h2 className="text-xl font-semibold text-gray-800 mb-3">Fair Play</h2>
                <div className="space-y-2 text-gray-600">
                <ul className="list-disc pl-5 space-y-1">
                  <li>Only one entry per participant is allowed.</li>
                  <li>Multiple entries or any attempt to cheat will result in disqualification.</li>
                </ul>
                </div>
              </div>
            </div>
            
            {/* Real-Time Updates */}
            <div className="flex gap-4">
              <div className="flex-shrink-0">
                <div className="bg-indigo-100 p-3 rounded-full">
                  <Bell className="h-6 w-6 text-indigo-600" />
                </div>
              </div>
              <div>
                <h2 className="text-xl font-semibold text-gray-800 mb-3">Real-Time Updates</h2>
                <div className="space-y-2 text-gray-600">
                <ul className="list-disc pl-5 space-y-1">
                  <li>Your prediction results will be updated after every match day.</li>
                  <li>The leaderboard will reflect your performance based on correct answers.</li>
                </ul>
                </div>
              </div>
            </div>
            
            {/* Prize Distribution */}
            <div className="flex gap-4">
              <div className="flex-shrink-0">
                <div className="bg-indigo-100 p-3 rounded-full">
                  <Award className="h-6 w-6 text-indigo-600" />
                </div>
              </div>
              <div>
                <h2 className="text-xl font-semibold text-gray-800 mb-3">Prize Distribution</h2>
                <div className="space-y-2 text-gray-600">
                  <ul className="list-disc pl-5 space-y-1">
                    <li>Winners will be rewarded as per the announced prize structure.</li>
                  </ul>
                </div>
              </div>
            </div>
            
            {/* Final Decision */}
            <div className="bg-gray-50 p-4 rounded-lg">
              <p className="font-medium text-gray-800">In case of disputes, the organizer's decision is final.</p>
            </div>
          </div>
          
          {/* CTA Button */}
          <div className="mt-8 text-center">
            <a
              href="/quiz"
              onClick={(e) => { e.preventDefault(); window.location.href = '/quiz'; }}
              className="inline-flex items-center gap-2 px-8 py-3 bg-indigo-600 text-white font-medium rounded-lg hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-colors duration-200"
            >
              Make Your Prediction
              <ArrowRight className="h-5 w-5" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RulesPage;