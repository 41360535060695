import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Link, useNavigate } from 'react-router-dom';
import { createUserWithEmailAndPassword, updateProfile } from 'firebase/auth';
import { collection, query, where, getDocs, doc, setDoc } from 'firebase/firestore';
import { auth, db } from '../firebase/config';
import Swal from 'sweetalert2';

const Signup = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    qualification: '',
    place: '',
    team: '',
    password: '',
    confirmPassword: '',
    consent: false
  });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const teams = [
    { value: 'csk', label: 'Chennai Super Kings' },
    { value: 'mi', label: 'Mumbai Indians' },
    { value: 'rcb', label: 'Royal Challengers Bangalore' },
    { value: 'kkr', label: 'Kolkata Knight Riders' },
    { value: 'dc', label: 'Delhi Capitals' },
    { value: 'srh', label: 'Sunrisers Hyderabad' },
    { value: 'rr', label: 'Rajasthan Royals' },
    { value: 'pbks', label: 'Punjab Kings' },
    { value: 'gt', label: 'Gujarat Titans' },
    { value: 'lsg', label: 'Lucknow Super Giants' }
  ];

  const qualifications = [
    { value: 'high_school', label: 'High School' },
    { value: 'bachelors', label: 'Bachelor\'s Degree' },
    { value: 'masters', label: 'Master\'s Degree' },
    { value: 'phd', label: 'Ph.D.' },
    { value: 'other', label: 'Other' }
  ];

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value
    });
  };

  // Check if user already exists
  const checkUserExists = async (email, phone) => {
    const emailQuery = query(
      collection(db, "users"),
      where("email", "==", email)
    );
    
    const phoneQuery = query(
      collection(db, "users"),
      where("phone", "==", phone)
    );
    
    const emailResults = await getDocs(emailQuery);
    const phoneResults = await getDocs(phoneQuery);
    
    if (!emailResults.empty) {
      return "email";
    }
    
    if (!phoneResults.empty) {
      return "phone";
    }
    
    return false;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);
    
    try {
      // Basic validation
      if (!formData.name || 
          !formData.email || 
          !formData.password || 
          !formData.confirmPassword || 
          !formData.phone || 
          !formData.qualification || 
          !formData.place || 
          !formData.team) {
        setError('Please fill in all required fields');
        setLoading(false);
        return;
      }
      
      if (formData.password !== formData.confirmPassword) {
        setError('Passwords do not match');
        setLoading(false);
        return;
      }
      
      if (formData.password.length < 6) {
        setError('Password must be at least 6 characters');
        setLoading(false);
        return;
      }

      if (!formData.phone.match(/^\d{10}$/)) {
        setError('Please enter a valid 10-digit phone number');
        setLoading(false);
        return;
      }

      // Check if user exists
      const existingUser = await checkUserExists(formData.email, formData.phone);
      
      if (existingUser) {
        const fieldName = existingUser === "email" ? "email address" : "phone number";
        setError(`This ${fieldName} is already registered.`);
        
        // Show sweet alert
        Swal.fire({
          icon: 'error',
          title: 'User Already Exists',
          text: `A user with this ${fieldName} already exists in our system.`,
          confirmButtonColor: '#3085d6',
        });
        
        setLoading(false);
        return;
      }
      
      // Create user with Firebase Authentication
      const userCredential = await createUserWithEmailAndPassword(
        auth, 
        formData.email, 
        formData.password
      );
      
      // Update profile with display name
      await updateProfile(userCredential.user, {
        displayName: formData.name
      });
      
      // Prepare user data for Firestore (remove password fields)
      const userData = {
        uid: userCredential.user.uid,
        name: formData.name,
        email: formData.email,
        phone: formData.phone,
        qualification: formData.qualification,
        place: formData.place,
        team: formData.team,
        consent: formData.consent,
        createdAt: new Date()
      };
      
      // Save user data to Firestore
      await setDoc(doc(db, "users", userCredential.user.uid), userData);
      
      // Show success message
      Swal.fire({
        icon: 'success',
        title: 'Account Created!',
        text: 'Your account has been created successfully. Welcome to Best11!',
        confirmButtonColor: '#3085d6',
      }).then(() => {
        // Navigate to dashboard or homepage
        navigate('/dashboard');
      });
      
    } catch (err) {
      console.error("Error during signup:", err);
      
      // Handle specific Firebase Auth errors
      if (err.code === 'auth/email-already-in-use') {
        setError('This email is already in use. Please try another one.');
      } else if (err.code === 'auth/invalid-email') {
        setError('Please enter a valid email address.');
      } else if (err.code === 'auth/weak-password') {
        setError('Password is too weak. Please choose a stronger password.');
      } else {
        setError('Failed to create account. Please try again.');
      }
      
      // Show error alert
      Swal.fire({
        icon: 'error',
        title: 'Signup Failed',
        text: error || 'Failed to create your account. Please try again.',
        confirmButtonColor: '#3085d6',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <section className="bg-gradient-to-r from-primary-light to-primary-dark text-white py-16 md:py-24 relative overflow-hidden min-h-screen flex items-center">
      <div className="absolute inset-0 bg-[url('/public/stadium-bg.jpg')] bg-cover bg-center opacity-20"></div>
      <div className="container mx-auto px-4 relative z-10">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="max-w-lg mx-auto bg-white rounded-lg overflow-hidden shadow-xl"
        >
          <div className="bg-primary-dark py-4 px-6">
            <h2 className="text-2xl font-bold text-center">Join Best11</h2>
          </div>
          
          <div className="p-6 bg-white text-gray-800">
            {error && (
              <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
                {error}
              </div>
            )}
            
            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
                  Full Name *
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <i className="ri-user-line text-gray-400"></i>
                  </div>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    className="shadow appearance-none border rounded w-full py-2 pl-10 pr-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-primary-light"
                    placeholder="Enter your full name"
                    required
                  />
                </div>
              </div>
              
              <div>
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                  Email Address *
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <i className="ri-mail-line text-gray-400"></i>
                  </div>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className="shadow appearance-none border rounded w-full py-2 pl-10 pr-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-primary-light"
                    placeholder="Enter your email"
                    required
                  />
                </div>
              </div>
              
              <div>
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="phone">
                  Contact Number *
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <i className="ri-phone-line text-gray-400"></i>
                  </div>
                  <input
                    type="tel"
                    id="phone"
                    name="phone"
                    pattern="[0-9]{10}"
                    value={formData.phone}
                    onChange={handleChange}
                    className="shadow appearance-none border rounded w-full py-2 pl-10 pr-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-primary-light"
                    placeholder="Enter your contact number"
                    required
                  />
                </div>
              </div>
              
              <div>
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="qualification">
                  Qualification *
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <i className="ri-graduation-cap-line text-gray-400"></i>
                  </div>
                  <select
                    id="qualification"
                    name="qualification"
                    value={formData.qualification}
                    onChange={handleChange}
                    className="shadow appearance-none border rounded w-full py-2 pl-10 pr-8 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-primary-light"
                    required
                  >
                    <option value="">Select your qualification</option>
                    {qualifications.map((qual) => (
                      <option key={qual.value} value={qual.value}>
                        {qual.label}
                      </option>
                    ))}
                  </select>
                  <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    <i className="ri-arrow-down-s-line text-gray-400"></i>
                  </div>
                </div>
              </div>
              
              <div>
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="place">
                  Place *
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <i className="ri-map-pin-line text-gray-400"></i>
                  </div>
                  <input
                    type="text"
                    id="place"
                    name="place"
                    value={formData.place}
                    onChange={handleChange}
                    className="shadow appearance-none border rounded w-full py-2 pl-10 pr-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-primary-light"
                    placeholder="Enter your location"
                    required
                  />
                </div>
              </div>
              
              <div>
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="team">
                  Favorite IPL Team *
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <i className="ri-team-line text-gray-400"></i>
                  </div>
                  <select
                    id="team"
                    name="team"
                    value={formData.team}
                    onChange={handleChange}
                    className="shadow appearance-none border rounded w-full py-2 pl-10 pr-8 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-primary-light"
                    required
                  >
                    <option value="">Select your favorite team</option>
                    {teams.map((team) => (
                      <option key={team.value} value={team.value}>
                        {team.label}
                      </option>
                    ))}
                  </select>
                  <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    <i className="ri-arrow-down-s-line text-gray-400"></i>
                  </div>
                </div>
              </div>
              
              <div>
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
                  Password *
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <i className="ri-lock-line text-gray-400"></i>
                  </div>
                  <input
                    type="password"
                    id="password"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    className="shadow appearance-none border rounded w-full py-2 pl-10 pr-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-primary-light"
                    placeholder="Create a password"
                    required
                  />
                </div>
              </div>
              
              <div>
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="confirmPassword">
                  Confirm Password *
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <i className="ri-lock-line text-gray-400"></i>
                  </div>
                  <input
                    type="password"
                    id="confirmPassword"
                    name="confirmPassword"
                    value={formData.confirmPassword}
                    onChange={handleChange}
                    className="shadow appearance-none border rounded w-full py-2 pl-10 pr-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-primary-light"
                    placeholder="Confirm your password"
                    required
                  />
                </div>
              </div>
              
              <div className="flex items-center mt-4">
                <input
                  type="checkbox"
                  id="consent"
                  name="consent"
                  checked={formData.consent}
                  onChange={handleChange}
                  className="h-4 w-4 text-primary-dark focus:ring-primary-light border-gray-300 rounded"
                  required
                />
                <label htmlFor="consent" className="ml-2 block text-xs text-gray-500">
                  I agree to receive marketing communications and newsletters. I understand I can unsubscribe at any time. By submitting this form, I consent to the processing of my personal data in accordance with the Privacy Policy.
                </label>
              </div>
              
              <div className="flex items-center mt-4">
                <input
                  type="checkbox"
                  id="terms"
                  name="terms"
                  className="h-4 w-4 text-primary-dark focus:ring-primary-light border-gray-300 rounded"
                  required
                />
                <label htmlFor="terms" className="ml-2 block text-sm text-gray-700">
                  I agree to the <a href="/terms" className="text-primary-dark hover:underline">Terms of Service</a> and <a href="/privacy" className="text-primary-dark hover:underline">Privacy Policy</a>
                </label>
              </div>
              
              <button
                type="submit"
                disabled={loading}
                className="w-full bg-primary-dark hover:bg-primary-light text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-200 mt-6 flex items-center justify-center"
              >
                {loading ? (
                  <>
                    <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    Creating Account...
                  </>
                ) : (
                  'Create Account'
                )}
              </button>
            </form>
            
            <div className="text-center mt-6">
              <p className="text-sm text-gray-600">
                Already have an account?{' '}
                <Link to="/login" className="text-primary-dark font-bold hover:text-primary-light">
                  Log in
                </Link>
              </p>
            </div>
          </div>
        </motion.div>
      </div>
    </section>
  );
};

export default Signup;