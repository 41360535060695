// src/components/HowItWorks.jsx
import React from 'react';
import { motion } from 'framer-motion';

const steps = [
  {
    icon: "👥",
    title: "Pick Your Best11",
    description: "Select 11 players from all teams before the tournament. Build your dream team with strategy and insight."
  },
  {
    icon: "📊",
    title: "Earn Points",
    description: "Players score points based on real match performances. Watch your team climb the leaderboard!"
  },
  {
    icon: "🏆",
    title: "Win Big",
    description: "The user with the most points wins exciting prizes! Compete against the best to claim victory."
  }
];

const HowItWorks = () => {
  return (
    <section className="py-16 bg-yellow-50">
      <div className="container mx-auto px-4">
        <motion.h2 
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          viewport={{ once: true }}
          className="text-3xl md:text-4xl font-bold text-center mb-12"
        >
          How It Works
        </motion.h2>
        
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {steps.map((step, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.2 }}
              viewport={{ once: true }}
              className="bg-white p-6 rounded-lg shadow-lg text-center"
            >
              <div className="w-16 h-16 bg-primary-light text-white text-2xl rounded-full flex items-center justify-center mx-auto mb-4">
                {step.icon}
              </div>
              <h3 className="text-xl font-bold mb-3">{step.title}</h3>
              <p className="text-gray-600">{step.description}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default HowItWorks;