import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { collection, addDoc, query, where, getDocs } from 'firebase/firestore';
import { db } from '../firebase/config';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

const PredictionForm = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    qualification: '',
    place: '',
    team: '',
    consent: false
  });
  
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  
  const teams = [
    { value: 'csk', label: 'Chennai Super Kings' },
    { value: 'mi', label: 'Mumbai Indians' },
    { value: 'rcb', label: 'Royal Challengers Bangalore' },
    { value: 'kkr', label: 'Kolkata Knight Riders' },
    { value: 'dc', label: 'Delhi Capitals' },
    { value: 'srh', label: 'Sunrisers Hyderabad' },
    { value: 'rr', label: 'Rajasthan Royals' },
    { value: 'pbks', label: 'Punjab Kings' },
    { value: 'gt', label: 'Gujarat Titans' },
    { value: 'lsg', label: 'Lucknow Super Giants' }
  ];

  const qualifications = [
    { value: 'high_school', label: 'High School' },
    { value: 'bachelors', label: 'Bachelor\'s Degree' },
    { value: 'masters', label: 'Master\'s Degree' },
    { value: 'phd', label: 'Ph.D.' },
    { value: 'other', label: 'Other' }
  ];
  
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => ({ 
      ...prev, 
      [name]: type === 'checkbox' ? checked : value 
    }));
  };
  
  // Check if user already exists
  const checkUserExists = async (email, phone) => {
    const emailQuery = query(
      collection(db, "user_data"),
      where("email", "==", email)
    );
    
    const phoneQuery = query(
      collection(db, "user_data"),
      where("phone", "==", phone)
    );
    
    const emailResults = await getDocs(emailQuery);
    const phoneResults = await getDocs(phoneQuery);
    
    if (!emailResults.empty) {
      return "email";
    }
    
    if (!phoneResults.empty) {
      return "phone";
    }
    
    return false;
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    
    try {
      // Check if user exists
      const existingUser = await checkUserExists(formData.email, formData.phone);
      
      if (existingUser) {
        const fieldName = existingUser === "email" ? "email address" : "phone number";
        setError(`This ${fieldName} is already registered.`);
        
        // Show sweet alert
        Swal.fire({
          icon: 'error',
          title: 'User Already Exists',
          text: `A user with this ${fieldName} already exists in our system.`,
          confirmButtonColor: '#3085d6',
        });
        
        setLoading(false);
        return;
      }
      
      // Add to Firestore
      await addDoc(collection(db, "user_data"), {
        ...formData,
        timestamp: new Date()
      });
      
      // Show success message
      Swal.fire({
        icon: 'success',
        title: 'Success!',
        text: 'Your information has been submitted successfully.',
        confirmButtonColor: '#3085d6',
      }).then(() => {
        // Navigate to rules page
        navigate('/rules');
      });
      
    } catch (err) {
      setError('Failed to submit your information. Please try again.');
      console.error(err);
      
      // Show error alert
      Swal.fire({
        icon: 'error',
        title: 'Submission Failed',
        text: 'Failed to submit your information. Please try again.',
        confirmButtonColor: '#3085d6',
      });
    } finally {
      setLoading(false);
    }
  };
  
  return (
    <div className="max-w-3xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
      <div className="bg-white shadow-lg rounded-lg p-8">
        <div className="text-center mb-8">
          <h1 className="text-3xl font-bold text-gray-900">Personal Information</h1>
          <p className="mt-2 text-gray-600">Please fill in your details to get started</p>
        </div>
        
        {error && (
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-6">
            {error}
          </div>
        )}
        
        <form id="personalForm" className="space-y-6" onSubmit={handleSubmit}>
          <div>
            <label className="block text-sm font-medium text-gray-700">Full Name *</label>
            <div className="mt-1 relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <i className="ri-user-line text-gray-400"></i>
              </div>
              <input
                type="text"
                required
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary text-sm"
                placeholder="Enter your full name"
              />
            </div>
          </div>
          
          <div>
            <label className="block text-sm font-medium text-gray-700">Email Address *</label>
            <div className="mt-1 relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <i className="ri-mail-line text-gray-400"></i>
              </div>
              <input
                type="email"
                required
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary text-sm"
                placeholder="Enter your email address"
              />
            </div>
          </div>
          
          <div>
            <label className="block text-sm font-medium text-gray-700">Contact Number *</label>
            <div className="mt-1 relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <i className="ri-phone-line text-gray-400"></i>
              </div>
              <input
                type="tel"
                required
                name="phone"
                pattern="[0-9]{10}"
                value={formData.phone}
                onChange={handleChange}
                className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary text-sm"
                placeholder="Enter your contact number"
              />
            </div>
          </div>
          
          <div>
            <label className="block text-sm font-medium text-gray-700">Qualification *</label>
            <div className="mt-1 relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <i className="ri-graduation-cap-line text-gray-400"></i>
              </div>
              <select
                required
                name="qualification"
                value={formData.qualification}
                onChange={handleChange}
                className="block w-full pl-10 pr-8 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary text-sm appearance-none"
              >
                <option value="">Select your qualification</option>
                {qualifications.map((qual) => (
                  <option key={qual.value} value={qual.value}>
                    {qual.label}
                  </option>
                ))}
              </select>
              <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <i className="ri-arrow-down-s-line text-gray-400"></i>
              </div>
            </div>
          </div>
          
          <div>
            <label className="block text-sm font-medium text-gray-700">Place *</label>
            <div className="mt-1 relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <i className="ri-map-pin-line text-gray-400"></i>
              </div>
              <input
                type="text"
                required
                name="place"
                value={formData.place}
                onChange={handleChange}
                className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary text-sm"
                placeholder="Enter your location"
              />
            </div>
          </div>
          
          <div>
            <label className="block text-sm font-medium text-gray-700">Favorite IPL Team *</label>
            <div className="mt-1 relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <i className="ri-team-line text-gray-400"></i>
              </div>
              <select
                required
                name="team"
                value={formData.team}
                onChange={handleChange}
                className="block w-full pl-10 pr-8 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary text-sm appearance-none"
              >
                <option value="">Select your favorite team</option>
                {teams.map((team) => (
                  <option key={team.value} value={team.value}>
                    {team.label}
                  </option>
                ))}
              </select>
              <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <i className="ri-arrow-down-s-line text-gray-400"></i>
              </div>
            </div>
          </div>
          
          <div className="space-y-4">
            <div className="flex items-start">
              <div className="flex items-center h-5">
                <input
                  type="checkbox"
                  required
                  id="consent"
                  name="consent"
                  checked={formData.consent}
                  onChange={handleChange}
                  className="h-4 w-4 text-primary border-gray-300 rounded focus:ring-primary"
                />
              </div>
              <div className="ml-3">
                <label htmlFor="consent" className="text-xs text-gray-500">
                  I agree to receive marketing communications and newsletters. I understand I can unsubscribe at any time. By submitting this form, I consent to the processing of my personal data in accordance with the Privacy Policy.
                </label>
              </div>
            </div>
            
            <div>
              <button
                type="submit"
                disabled={loading}
                className="w-full sm:w-auto px-8 py-3 bg-primary text-white font-medium rounded-button hover:bg-primary/90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary transition-colors duration-200 cursor-pointer whitespace-nowrap flex items-center justify-center"
              >
                {loading ? (
                  <>
                    <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    Processing...
                  </>
                ) : (
                  <>
                    <i className="ri-arrow-right-line mr-2"></i>
                    Start Now
                  </>
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PredictionForm;