// src/components/Stats.jsx
import React from 'react';
import { motion } from 'framer-motion';

const statsData = [
  { value: "1M+", label: "Active Players" },
  { value: "₹10Cr", label: "Total Prizes" },
  { value: "50+", label: "Tournaments" },
  { value: "100K+", label: "Winners" },
];

const Stats = () => {
  return (
    <section className="bg-background-light py-10">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-2 md:grid-cols-4 gap-6 text-center">
          {statsData.map((stat, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              viewport={{ once: true }}
              className="p-4"
            >
              <h3 className="text-3xl md:text-4xl font-bold text-primary-dark mb-2">{stat.value}</h3>
              <p className="text-gray-600">{stat.label}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Stats;