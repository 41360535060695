import React, { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getFirestore, doc, setDoc, getDoc, serverTimestamp } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom'; // Import for navigation
import { db, auth } from '../firebase/config';

const QuizApp = () => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answer, setAnswer] = useState('');
  const [showHint, setShowHint] = useState(false);
  const [timeLeft, setTimeLeft] = useState(900); // 15 minutes in seconds
  const [feedback, setFeedback] = useState({ show: false, message: '', type: '' });
  const [userId, setUserId] = useState(null);
  const [userProgress, setUserProgress] = useState({
    completedQuestions: [],
    currentQuestion: 0,
    startTime: null,
    answers: {} // Object to store answers with question IDs as keys
  });
  const [showCompletionPopup, setShowCompletionPopup] = useState(false);
  const [isExpired, setIsExpired] = useState(false);
  const navigate = useNavigate();

  // Set expiration date - April 5, 2025
  const EXPIRATION_DATE = new Date('April 5, 2025 23:59:59').getTime();

  // Check if current date is past the expiration date
  useEffect(() => {
    const checkExpiration = () => {
      const now = new Date().getTime();
      if (now > EXPIRATION_DATE) {
        setIsExpired(true);
      }
    };

    // Check immediately
    checkExpiration();

    // Also set an interval to check regularly (in case user keeps the page open)
    const expirationInterval = setInterval(checkExpiration, 60000); // Check every minute
    
    return () => clearInterval(expirationInterval);
  }, []);

  // Mock questions (these would typically come from your database)
  const questions = [
    {
      id: 1,
      question: "Which team will win the opening match of IPL 2025?",
      hints: [
        "2024: Gujarat Titans won the opening match.",
        "2023: Chennai Super Kings started with a win.",
        " 2022: Kolkata Knight Riders began with a dominant victory.",
        "Defending champions have often performed well in the opening match. Will this trend continue in 2025?"
      ],
    },
    {
      id: 2,
      question: "Who will score the most runs in IPL 2025? (Orange Cap winner)",
      hints: [
        "2024: Shubman Gill (890 runs) 🏆",
        "2023: Faf du Plessis (730 runs)",
        "2022: Jos Buttler (863 runs)",
        "op-order batters have consistently dominated. Will a youngster like Yashasvi Jaiswal break into this elite list?"
      ],
    },
    {
      id: 3,
      question: "Who will take the most wickets in IPL 2025? (Purple Cap winner)",
      hints: [
        "2024: Mohammed Shami (28 wickets) 🏆",
        "2023: Mohammed Shami (27 wickets)",
        "2022: Yuzvendra Chahal (27 wickets)",
        "Pacers have dominated the last two seasons, but can spinners reclaim the Purple Cap?"
      ],
    },
    {
      id: 4,
      question: "Will any batsman score a century in the first 10 matches? (Yes/No)",
      hints: [
        "2024: Kohli and Buttler hit early centuries.",
        "2023: Harry Brook smashed a ton in the first 10 games.",
        "2022: Jos Buttler hit multiple early centuries.",
        "Big hitters love early momentum. Can we expect another explosive start?"
      ],
    },
    {
      id: 5,
      question: "Which team will hit the most sixes in IPL 2025?",
      hints: [
        "2024: CSK & MI dominated with the most sixes.",
        "2023: RCB & RR were six-hitting machines.",
        "2022: Rajasthan Royals (RR) led the charts.",
        "Power-hitting teams like KKR, MI, and CSK always lead in this department. Will they continue their dominance?"
      ],
    },
    {
      id: 6,
      question: "Who will hit the first 100 of the season?",
      hints: [
        "2024: Virat Kohli",
        "2023: Harry Brook",
        "2022: Jos Buttler",
        "Top-order players have hit the first century in recent seasons. Will a middle-order surprise us this time?"
      ],
    },
    {
      id: 7,
      question: "Which bowler will take the first 5-wicket haul in IPL 2025?",
      hints: [
        "2024: Mark Wood took a fiery 5-for early in the season.",
        "2023: Arshdeep Singh took an early 5-wicket haul.",
        "2022: Yuzvendra Chahal's 5-for was crucial for RR.",
        "Swing bowlers tend to get early wickets, while leg-spinners shine in the middle phase."
      ],
    },
    {
      id: 8,
      question: "Will any team score 250+ runs in a single innings? (Yes/No)",
      hints: [
        "2024: KKR & RCB came close.",
        "2023: CSK nearly breached 250.",
        "2022: RCB set a 235-run total.",
        "With improving pitches and power-hitters, is this the year a team crosses 250?"
      ],
    },
    {
      id: 9,
      question: "Which team will have the best Net Run Rate (NRR) after the league stage?",
      hints: [
        "2024: Gujarat Titans (+NRR) topped the league.",
        "2023: Rajasthan Royals had the highest NRR early on.",
        "2022: Lucknow Super Giants started with a strong NRR.",
        "Teams with explosive batting and strong bowling usually dominate NRR. Will GT, MI, or RR lead again?"        
      ],
    },
    {
      id: 10,
      question: "Who will win the IPL 2025 Purple Cap (Most Wickets)?",
      hints: [
        "Fast bowlers like Shami and Harshal Patel have dominated. Can Chahal or Rashid Khan bring the Purple Cap back to spinners?"
      ],
    },
    {
      id: 11,
      question: "Which team will finish at the bottom of the points table?",
      hints: [
        "SRH finished last in 2023. KKR struggled in 2022. Who will have a tough season in 2025?"
      ],
    },
    {
      id: 12,
      question: "Who will be the first player to score 500+ runs in the season?",
      hints: [
        "Gill, Faf, and Kohli have reached 500 runs first in recent years. Will they do it again?"
      ],
    },
    {
      id: 13,
      question: "who (bowler) will take a hat-trick this season?",
      hints: [
        "Rashid Khan and Chahal have done it before. Will we see another magical spell?"
      ],
    },
    {
      id: 14,
      question: "Which player will hit the longest six of IPL 2025?",
      hints: [
        "Livingstone (117m), Russell (112m), and Maxwell (108m) have led this chart in the past. Who will break the record?"
      ],
    },
    {
      id: 15,
      question: "Who will take a wicket on the first ball of a match?",
      hints: [
        "Bhuvneshwar Kumar & Boult are famous for this. Can they do it again?"
      ],
    },
    {
      id: 16,
      question: "How many centuries will be scored in IPL 2025?",
      hints: [
        "Last 3 seasons saw 10+ centuries. With better pitches, can we cross 15?"
      ],
    },
    {
      id: 17,
      question: "Which wicketkeeper will have the most dismissals in the season?",
      hints: [
        "Dhoni, Samson, and Ishan Kishan have led this list. Will a new name emerge?"
      ],
    },
    {
      id: 18,
      question: "Who will have the best bowling economy in IPL 2025?",
      hints: [
        "Sunil Narine & Rashid Khan have been the most economical in recent years."
      ],
    },
    {
      id: 19,
      question: "Will we see a Super Over in IPL 2025?",
      hints: [
        "Multiple Super Overs in 2023 & 2024 made headlines. Will it happen again?"
      ],
    },
    {
      id: 20,
      question: " Which uncapped player will score the most runs?",
      hints: [
        "Rinku Singh & Jitesh Sharma emerged as stars. Who will be next?"
      ],
    },
    {
      id: 21,
      question: "Which team will take the most catches?",
      hints: [
        "MI & RR have been sharpest in fielding. Will they stay on top?"
      ],
    },
    {
      id: 22,
      question: "Who will be IPL 2025 MVP?",
      hints: [
        "Past MVPs: Stokes, Russell, Gill. Who will be the standout performer?"
      ],
    }
  ];

  // Make sure we have a valid question index
  useEffect(() => {
    if (currentQuestionIndex >= questions.length) {
      setCurrentQuestionIndex(questions.length - 1);
    } else if (currentQuestionIndex < 0) {
      setCurrentQuestionIndex(0);
    }
  }, [currentQuestionIndex, questions.length]);

  // Get the current question safely
  const currentQuestion = questions[currentQuestionIndex] || questions[0];

  // Check for user authentication
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserId(user.uid);
        loadUserProgress(user.uid);
      } else {
        setUserId(null);
      }
    });

    return () => unsubscribe();
  }, []);

  // Load user progress from Firestore
  const loadUserProgress = async (uid) => {
    try {
      const docRef = doc(db, "quizProgress", uid);
      const docSnap = await getDoc(docRef);
      
      if (docSnap.exists()) {
        const data = docSnap.data();
        // Ensure we have a valid index
        const safeIndex = Math.min(data.currentQuestion, questions.length - 1);
        
        setUserProgress(data);
        setCurrentQuestionIndex(safeIndex);
        
        // Load current answer if it exists
        const currentQuestionId = questions[safeIndex].id;
        if (data.answers && data.answers[currentQuestionId]) {
          setAnswer(data.answers[currentQuestionId]);
        }
      } else {
        // Initialize new user progress
        const newProgress = {
          completedQuestions: [],
          currentQuestion: 0,
          startTime: serverTimestamp(),
          answers: {} // Initialize empty answers object
        };
        setUserProgress(newProgress);
        await setDoc(docRef, newProgress);
      }
    } catch (error) {
      console.error("Error loading user progress:", error);
    }
  };

  // Save user progress to Firestore
  const saveUserProgress = async (newProgress) => {
    if (!userId) return;
    
    try {
      const docRef = doc(db, "quizProgress", userId);
      await setDoc(docRef, newProgress);
      
      // Save the answer separately in a user-specific answers collection
      const answerDocRef = doc(db, "userAnswers", userId);
      await setDoc(answerDocRef, {
        userId: userId,
        answers: newProgress.answers
      }, { merge: true });
    } catch (error) {
      console.error("Error saving user progress:", error);
    }
  };

  // Timer effect
  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(prevTime => {
        if (prevTime <= 1) {
          clearInterval(timer);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  // Format time as mm:ss
  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  // Function to format the expiration date
  const formatExpirationDate = () => {
    const date = new Date(EXPIRATION_DATE);
    return date.toLocaleDateString('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric'
    });
  };

  // Create confetti effect
  const createConfetti = () => {
    const confettiContainer = document.createElement('div');
    confettiContainer.style.position = 'fixed';
    confettiContainer.style.top = '0';
    confettiContainer.style.left = '0';
    confettiContainer.style.width = '100%';
    confettiContainer.style.height = '100%';
    confettiContainer.style.overflow = 'hidden';
    confettiContainer.style.pointerEvents = 'none';
    confettiContainer.style.zIndex = '9999';
    document.body.appendChild(confettiContainer);

    const colors = ['#ff0000', '#00ff00', '#0000ff', '#ffff00', '#ff00ff', '#00ffff'];
    
    // Create confetti pieces
    for (let i = 0; i < 200; i++) {
      setTimeout(() => {
        const confetti = document.createElement('div');
        const color = colors[Math.floor(Math.random() * colors.length)];
        const size = Math.random() * 10 + 5;
        const left = Math.random() * 100;
        const isCircle = Math.random() > 0.5;
        
        confetti.style.position = 'absolute';
        confetti.style.left = `${left}%`;
        confetti.style.top = '-20px';
        confetti.style.width = `${size}px`;
        confetti.style.height = `${size}px`;
        confetti.style.backgroundColor = color;
        confetti.style.borderRadius = isCircle ? '50%' : '0';
        confetti.style.transform = `rotate(${Math.random() * 360}deg)`;
        
        confettiContainer.appendChild(confetti);
        
        // Animate confetti falling
        const fallDuration = Math.random() * 3 + 2;
        const swayAmount = Math.random() * 20 - 10;
        
        confetti.animate(
          [
            { transform: `translateY(0) translateX(0) rotate(0deg)` },
            { transform: `translateY(${window.innerHeight}px) translateX(${swayAmount}px) rotate(${Math.random() * 360}deg)` }
          ],
          {
            duration: fallDuration * 1000,
            easing: 'cubic-bezier(0.37, 0, 0.63, 1)'
          }
        );
        
        // Remove confetti after animation
        setTimeout(() => {
          confetti.remove();
        }, fallDuration * 1000);
      }, Math.random() * 3000);
    }
    
    // Remove container after all confetti is gone
    setTimeout(() => {
      confettiContainer.remove();
    }, 8000);
  };

  // Show completion popup and redirect
  const showCompletionAndRedirect = () => {
    setShowCompletionPopup(true);
    createConfetti();
    
    // Navigate to home after 2 minutes
    setTimeout(() => {
      navigate('/');
    }, 120000); // 2 minutes in milliseconds
  };

  // Handle next question without checking answer
  const handleNextQuestion = async () => {
    if (!currentQuestion || isExpired) return;
    
    // Save user's answer with the question ID as the key
    const updatedAnswers = {
      ...userProgress.answers,
      [currentQuestion.id]: answer.trim() // Use question ID as key
    };
    
    const nextQuestionIndex = currentQuestionIndex + 1;
    const updatedProgress = {
      ...userProgress,
      completedQuestions: [...userProgress.completedQuestions, currentQuestion.id],
      currentQuestion: nextQuestionIndex,
      answers: updatedAnswers
    };
    
    // Save to Firebase
    await saveUserProgress(updatedProgress);
    setUserProgress(updatedProgress);
    
    // Show feedback
    setFeedback({
      show: true,
      message: 'Answer submitted!',
      type: 'success'
    });
    
    // Hide feedback after 3 seconds
    setTimeout(() => {
      setFeedback({ show: false, message: '', type: '' });
    }, 3000);
    
    // Check if this was the last question
    if (currentQuestionIndex >= questions.length - 1) {
      showCompletionAndRedirect();
    } else {
      // Move to next question
      setCurrentQuestionIndex(nextQuestionIndex);
      setAnswer('');
      setShowHint(false);
    }
  };

  // Handle previous question
  const handlePrevious = () => {
    if (currentQuestionIndex > 0 && !isExpired) {
      const prevIndex = currentQuestionIndex - 1;
      setCurrentQuestionIndex(prevIndex);
      
      // Load previous answer if it exists
      const prevQuestionId = questions[prevIndex].id;
      if (userProgress.answers && userProgress.answers[prevQuestionId]) {
        setAnswer(userProgress.answers[prevQuestionId]);
      } else {
        setAnswer('');
      }
      setShowHint(false);
    }
  };

  // If quiz is expired, show expiration alert
  if (isExpired) {
    return (
      <div className="bg-gray-50 min-h-screen flex items-center justify-center">
        <div className="max-w-md w-full bg-white rounded-lg shadow-lg p-8 text-center">
          <div className="text-6xl mb-6">⏰</div>
          <h2 className="text-2xl font-bold text-red-600 mb-4">Time Expired!</h2>
          <p className="text-gray-700 mb-6">
            The submission period for this quiz ended on {formatExpirationDate()}.
          </p>
          <p className="text-gray-500 mb-8">
            The results will be announced soon. Thank you for your participation!
          </p>
          <button 
            onClick={() => navigate('/')}
            className="px-6 py-3 bg-primary text-white rounded-button hover:bg-primary/90 transition-colors"
          >
            Return to Home
          </button>
        </div>
      </div>
    );
  }

  // If we somehow don't have a current question, show a loading state
  if (!currentQuestion) {
    return <div className="flex items-center justify-center min-h-screen">Loading...</div>;
  }

  return (
    <div className="bg-gray-50 min-h-screen">
      <div className="max-w-3xl mx-auto px-4 py-8">
        <div className="bg-white rounded-lg shadow-lg p-8">
          {/* Expiration notice */}
          <div className="bg-yellow-50 border border-yellow-200 rounded-lg p-4 mb-6">
            <div className="flex items-center">
              <div className="flex-shrink-0 text-yellow-500">
                <i className="ri-time-line"></i>
              </div>
              <div className="ml-3">
                <p className="text-sm text-yellow-700">
                  Submissions close on {formatExpirationDate()}. Make sure to complete all your answers before then!
                </p>
              </div>
            </div>
          </div>

          {/* Progress bar */}
          <div className="relative h-2 bg-gray-100 rounded-full mb-8">
            <div 
              className="absolute top-0 left-0 h-full bg-primary rounded-full" 
              style={{ width: `${(currentQuestionIndex / questions.length) * 100}%` }}
            ></div>
          </div>

          {/* Question counter and timer */}
          <div className="flex items-center justify-between mb-8">
            <div className="text-sm font-medium text-gray-500">
              Question {currentQuestionIndex + 1} of {questions.length}
            </div>
            <div className="flex items-center">
              <div className="w-8 h-8 flex items-center justify-center bg-primary/10 text-primary rounded-full">
                <i className="ri-time-line"></i>
              </div>
              <span className="ml-2 text-sm font-medium text-gray-700">{formatTime(timeLeft)}</span>
            </div>
          </div>

          {/* Question text */}
          <h2 className="text-2xl font-semibold text-gray-900 mb-6">{currentQuestion.question}</h2>
          
          {/* Answer input */}
          <div className="mb-8">
            <div className="relative">
              <input 
                type="text" 
                value={answer}
                onChange={(e) => setAnswer(e.target.value)}
                className="w-full px-4 py-3 border-2 border-gray-200 rounded focus:border-primary focus:ring-0 transition-colors text-gray-800" 
                placeholder="Type your answer here..."
              />
              <div className="absolute right-3 top-1/2 transform -translate-y-1/2">
                <i className="ri-pencil-line text-gray-400"></i>
              </div>
            </div>
          </div>

          {/* Hints */}
          <div className="mb-8">
            <button 
              onClick={() => setShowHint(!showHint)}
              className="flex items-center text-gray-600 hover:text-primary transition-colors"
            >
              <i className="ri-lightbulb-line mr-2"></i>
              <span>Need a hint?</span>
            </button>
            <div className={`mt-4 bg-gray-50 rounded-lg p-6 transition-all duration-300 ${showHint ? 'max-h-96 opacity-100' : 'max-h-0 opacity-0 overflow-hidden'}`}>
              <h3 className="font-medium text-gray-900 mb-3">Here are some helpful hints:</h3>
              <ul className="space-y-2 text-gray-600">
                {currentQuestion.hints.map((hint, index) => (
                  <li key={index} className="flex items-start">
                    <i className="ri-checkbox-blank-circle-line mt-1 mr-2 text-primary"></i>
                    <span>{hint}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          {/* Navigation buttons */}
          <div className="flex justify-between items-center">
            <button 
              onClick={handlePrevious}
              disabled={currentQuestionIndex === 0}
              className={`px-6 py-3 bg-gray-100 text-gray-600 rounded-button hover:bg-gray-200 transition-colors cursor-pointer whitespace-nowrap ${currentQuestionIndex === 0 ? 'opacity-50 cursor-not-allowed' : ''}`}
            >
              Previous
            </button>
            <button 
              onClick={handleNextQuestion}
              disabled={answer.trim() === ''}
              className={`px-6 py-3 bg-primary text-white rounded-button hover:bg-primary/90 transition-colors cursor-pointer whitespace-nowrap ${answer.trim() === '' ? 'opacity-50 cursor-not-allowed' : ''}`}
            >
              Next Question
            </button>
          </div>
        </div>
      </div>

      {/* Feedback notification */}
      {feedback.show && (
        <div className={`fixed top-4 right-4 px-6 py-3 rounded shadow-lg ${feedback.type === 'success' ? 'bg-green-500' : 'bg-red-500'} text-white`}>
          {feedback.message}
        </div>
      )}

      {/* Completion popup */}
      {showCompletionPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg shadow-xl p-8 max-w-md w-full text-center transform animate-bounce">
            <div className="text-6xl mb-4">🎉</div>
            <h2 className="text-2xl font-bold text-gray-900 mb-4">Congratulations!</h2>
            <p className="text-lg text-gray-700 mb-6">You've completed all the questions successfully!</p>
            <p className="text-sm text-gray-500">The Winner will be declared soon..</p>
            <div className="mt-6">
              <button 
                onClick={() => navigate('/')}
                className="px-6 py-3 bg-primary text-white rounded-button hover:bg-primary/90 transition-colors"
              >
                Return Home Now
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default QuizApp;